import { noop } from 'lodash';
import { createWorkerHandler } from './createHandler';

export function createWorkerHandlerForOOI(
  controllerConfig,
  { debug },
  flowAPI,
) {
  const { wixCodeApi } = controllerConfig;

  const setNewDimensions = (width, height) =>
    controllerConfig.setProps({
      dimensions: {
        width,
        height,
      },
    });

  return createWorkerHandler(
    controllerConfig,
    {
      handleSetWidgetHeight: (height, width) => setNewDimensions(width, height),
      handleFitIntoView: noop,
      handleResizeWindow: setNewDimensions,
      handleResizeComponent: ({ width, height }) =>
        setNewDimensions(width, height),
      handleLogin: async ({ mode, language }, onLogin, onError) => {
        try {
          const user = await wixCodeApi.user.promptLogin({ mode, language });

          onLogin({
            id: user.id,
            email: await wixCodeApi.user.currentUser.getEmail(),
          });
        } catch (error) {
          onError(error);
        }
      },
      handleLogout: wixCodeApi.user.logout,
      handleGoToPricingPlanPicker: () => {},
    },
    debug,
    flowAPI,
  );
}
