import * as React from 'react';

import { ISearchEventDocument, EventType } from '@wix/client-search-sdk';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';

import { classes as descriptionClasses } from '../../../components/SearchResults/Widget/components/Description/Description.st.css';
import { st, classes } from './EventListItem.st.css';
import { useFormatters } from '../../../components/SearchResults/Widget/hooks';
import { hasImage } from '../../../components/SearchResults/Widget/components/Thumbnail';
import { ListItemThumbnail } from '../../../components/SearchResults/Widget/components/ListItemThumbnail';
import { Title } from '../../../components/SearchResults/Widget/components/Title';
import { ListLayoutItemProps } from '../../../components/SearchResults/Widget/components/Layout.types';
import { xss } from '../../../components/SearchResults/Widget/lib/xss';

export enum EventListItemRenderMode {
  default = 'default',
  condensed = 'condensed',
}

interface IEventListItemProps extends ListLayoutItemProps {
  renderMode?: EventListItemRenderMode;
}

export const EventListItem: React.FC<IEventListItemProps> = ({
  item,
  onLinkClick,
  thumbnailHeight,
  thumbnailWidth,
  renderMode = EventListItemRenderMode.default,
}) => {
  const { isMobile, isEditorX } = useEnvironment();
  const { image, url, title } = item;
  const { formatCurrency, formatDate, formatTime } = useFormatters();
  const { t } = useTranslation();

  const getTranslatedEventTypeLabel = ({
    eventType,
    minPrice,
    maxPrice,
    currency,
  }: ISearchEventDocument) => {
    const translationMap: Partial<Record<EventType, () => string | undefined>> =
      {
        [EventType.paid]: () => {
          const translationKey =
            minPrice !== maxPrice
              ? 'searchResults.list.events.eventTypeLabel.paid'
              : 'searchResults.list.events.eventTypeLabel.paid.singlePrice';
          return currency
            ? t(translationKey, {
                minPrice: formatCurrency(minPrice, currency),
                maxPrice: formatCurrency(maxPrice, currency),
              })
            : undefined;
        },
      };

    const translation = translationMap[eventType];
    return translation ? translation() : undefined;
  };

  const getEventType = (event: ISearchEventDocument) => {
    return getTranslatedEventTypeLabel(event);
  };

  const renderTitle = () => {
    const eventTitle = (
      <Title
        title={item.title}
        url={item.url}
        onClick={(e) => onLinkClick?.(e, 'title')}
      />
    );
    const eventType = getEventType(item as ISearchEventDocument);
    return renderMode === EventListItemRenderMode.default ? (
      eventTitle
    ) : (
      <div>
        <div className={classes.condensedTitle}>
          <span
            className={st(classes.condensedTitleStrong, {
              narrow: !!eventType,
            })}
          >
            {eventTitle}
          </span>
          <div
            className={st(classes.condensedTitleEvent)}
            data-hook="event-type"
          >
            {eventType}
          </div>
        </div>
      </div>
    );
  };

  const renderTime = ({ startDate }: ISearchEventDocument) => {
    return startDate ? (
      <div data-hook="event-start" className={classes.eventDateTime}>
        <span data-hook="event-start-date">{formatDate(startDate)}</span>
        <span>&nbsp;|&nbsp;</span>
        <span data-hook="event-start-time">{formatTime(startDate)}</span>
      </div>
    ) : null;
  };

  const renderEventType = (event: ISearchEventDocument) => {
    const translation = getEventType(event);
    if (!translation) {
      return null;
    }
    return (
      <div className={classes.eventType} data-hook="event-type">
        {translation}
      </div>
    );
  };

  const renderDescription = () => {
    const event = item as ISearchEventDocument;
    const styles =
      renderMode === EventListItemRenderMode.condensed
        ? st(descriptionClasses.root, classes.condensedDescription)
        : descriptionClasses.root;
    return (
      <div className={styles}>
        {renderTime(event)}
        <div
          className={classes.eventLocation}
          data-hook="event-location"
          dangerouslySetInnerHTML={{ __html: xss(event.location) }}
        />
        {renderMode === EventListItemRenderMode.default &&
          renderEventType(event)}
      </div>
    );
  };

  return (
    <li
      className={st(classes.root, {
        fixed: !isEditorX,
        fluid: isEditorX,
        mobileView: isMobile,
      })}
      data-hook="list-layout-item"
    >
      {hasImage(image) && (
        <ListItemThumbnail
          height={thumbnailHeight}
          image={image}
          onLinkClick={onLinkClick}
          title={title}
          url={url}
          width={thumbnailWidth}
        />
      )}
      <div className={classes.content}>
        {renderTitle()}
        {renderDescription()}
      </div>
    </li>
  );
};
