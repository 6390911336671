import * as React from 'react';

import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { ResultsStatus } from '../ResultsStatus';
import { SortControl, SortControlProps } from '../SortControl';
import {
  getNoFacetsResultsFoundMessageKey,
  getNoFacetsResultsEmptyMessageKey,
  getFacetsResultsFoundMessageKey,
  getFacetsResultsEmptyMessageKey,
} from '../resultsMessage';
import settingsParams from '../../../settingsParams';
import { Spec } from '../../../../../lib/specs';

import { st, classes } from './StatusLine.st.css';

type StatusLineProps = SortControlProps & {
  totalCount: number;
  resultsCount: number;
  facetsEnabled: boolean;
  searchQuery?: string;
};

const getResultsMessageKey = ({
  totalCount,
  resultsCount,
  facetsEnabled,
  settings,
}: {
  resultsCount: number;
  totalCount: number;
  facetsEnabled: boolean;
  settings: {
    isResultsMessageWithNumber: boolean;
    isResultsMessageWithQuery: boolean;
    isResultsEmptyMessageWithNumber: boolean;
    isResultsEmptyMessageWithQuery: boolean;
  };
}) => {
  if (resultsCount > 0) {
    if (facetsEnabled) {
      return getFacetsResultsFoundMessageKey(
        {
          isWithNumber: settings.isResultsMessageWithNumber,
          isWithQuery: settings.isResultsMessageWithQuery,
        },
        resultsCount === totalCount,
      );
    } else {
      return getNoFacetsResultsFoundMessageKey({
        isWithNumber: settings.isResultsMessageWithNumber,
        isWithQuery: settings.isResultsMessageWithQuery,
      });
    }
  } else {
    if (facetsEnabled) {
      return getFacetsResultsEmptyMessageKey({
        isWithNumber: settings.isResultsEmptyMessageWithNumber,
        isWithQuery: settings.isResultsEmptyMessageWithQuery,
      });
    } else {
      return getNoFacetsResultsEmptyMessageKey();
    }
  }
};

export const StatusLine: React.FC<StatusLineProps> = ({
  facetsEnabled,
  resultsCount,
  totalCount,
  searchQuery = '',
  selectedSortOption,
  selectedDocumentType,
  onSortChange,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const settings = useSettings();

  const {
    isResultsEmptyMessageWithNumber,
    isResultsEmptyMessageWithQuery,
    isResultsMessageWithQuery,
    isResultsMessageWithNumber,
  } = settingsParams;

  const messageKey = getResultsMessageKey({
    settings: {
      isResultsEmptyMessageWithNumber: settings.get(
        isResultsEmptyMessageWithNumber,
      ),
      isResultsEmptyMessageWithQuery: settings.get(
        isResultsEmptyMessageWithQuery,
      ),
      isResultsMessageWithQuery: settings.get(isResultsMessageWithQuery),
      isResultsMessageWithNumber: settings.get(isResultsMessageWithNumber),
    },
    totalCount,
    resultsCount,
    facetsEnabled,
  });

  return (
    <div className={st(classes.root, { mobileView: isMobile })}>
      {!facetsEnabled && (
        <ResultsStatus
          text={t(messageKey, {
            number: resultsCount,
            query: searchQuery,
          })}
        />
      )}
      {facetsEnabled && resultsCount > 0 && (
        <ResultsStatus
          text={t(messageKey, {
            number: resultsCount,
            query: searchQuery,
            totalCount,
          })}
        />
      )}
      {resultsCount > 1 && (
        <SortControl
          selectedSortOption={selectedSortOption}
          selectedDocumentType={selectedDocumentType}
          onSortChange={onSortChange}
        />
      )}
    </div>
  );
};
