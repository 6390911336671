import _ from 'lodash';
import { requestPlayVideo } from './request-play-video';
import { requestPlay } from '../reducers/playback';
import { getNextVideoId } from '../lazy-channel-videos/selectors';
import { setOverlayContent } from './player/set-overlay-content';
import { isVolumeChangedByUser, isAutoplayed } from '../../selectors/player';
import { getVideoById } from '../../selectors/videos';
import { getVideoEndOption, isSingleVideo } from '../../selectors/app-settings';
import { getVideoCardsList } from '../../selectors/video-cards';
import { VIDEO_END_OPTIONS } from '@wix/wix-vod-constants/app-settings';
import INFO_OVERLAY_CONTENT from '../../player-widget/components/new-player/containers/animated-overlay-content/info-overlay-content-enums';
import { hidePlayerUI } from './player/ui';
import { isFacebookVideo } from '@wix/wix-vod-shared/common';
import { logWidgetVidClick } from '../../utils/bi/widget-common-events';
import { createProxy } from '../../worker/lib';
import { getChannelById, getCurrentChannelId } from '../../selectors/channels';

export const handleVideoEndDesktop = (fedopsLogger) =>
  handleVideoEnd(false, fedopsLogger);
export const handleVideoEndMobile = (fedopsLogger) =>
  handleVideoEnd(true, fedopsLogger);

const logNextVideoClick = (dispatch, state, nextVideoId) => {
  const videoItem = getVideoById(state, nextVideoId);
  dispatch(
    logWidgetVidClick({
      videoItem,
      channelData: getChannelById(state, getCurrentChannelId(state)),
    }),
  );
};

const desktopHandlers = {
  [VIDEO_END_OPTIONS.OPEN_ACTION_CARDS]: openActionCards,
  [VIDEO_END_OPTIONS.PLAY_NEXT_VIDEO]: playNextVideo,
};

const mobileHandlers = {
  [VIDEO_END_OPTIONS.PLAY_NEXT_VIDEO]: playNextVideoMobile,
};

export const handleVideoEnd = createProxy(
  'handleVideoEnd',
  (isMobile, fedopsLogger) => (dispatch, getState) => {
    const state = getState();

    if (!isSingleVideo(state)) {
      const videoEndOption = getVideoEndOption(state);
      const handlers = isMobile ? mobileHandlers : desktopHandlers;
      const handler = handlers[videoEndOption];

      if (handler) {
        handler(dispatch, state, fedopsLogger, isMobile);
      }
    }
  },
);

function playNextVideo(dispatch, state, fedopsLogger, isMobile) {
  const nextVideoId = getNextVideoId(state);
  const muted = isAutoplayed(state) && !isVolumeChangedByUser(state);

  if (nextVideoId) {
    logNextVideoClick(dispatch, state, nextVideoId, fedopsLogger, isMobile);
    dispatch(requestPlayVideo(nextVideoId, muted));
  }
}

function playNextVideoMobile(dispatch, state, fedopsLogger, isMobile) {
  const nextVideoId = getNextVideoId(state);

  if (nextVideoId) {
    const nextVideo = getVideoById(state, nextVideoId);

    if (!isFacebookVideo(nextVideo)) {
      logNextVideoClick(dispatch, state, nextVideoId, fedopsLogger, isMobile);
      dispatch(requestPlay(nextVideoId, true));
    }
  }
}

function openActionCards(dispatch, state) {
  const videoCards = getVideoCardsList(state);

  if (!_.isEmpty(videoCards)) {
    dispatch(hidePlayerUI());
    dispatch(setOverlayContent(INFO_OVERLAY_CONTENT.ALL_CARDS));
  }
}
