import _ from 'lodash';
import { createSelector } from 'reselect';
import {
  getThumbnailSpacing,
  getVideosInRowCount,
  getContainerMargins,
  getStretchToFullWidth,
  canShowVideoListItemContentBelow,
} from '../../selectors/app-settings';
import { WIDTH_CONSTRAINTS } from '../../constants/thumbnail-sizes';
import { FULL_WIDTH_MARGINS, MAX_SPACING } from './constants';

const getWorkingAreaWidth = createSelector(
  (state) => state.windowSize.width,
  getContainerMargins,
  getStretchToFullWidth,
  (width, containerMargins, isFullWidth) => {
    const singleMargin = isFullWidth ? containerMargins : FULL_WIDTH_MARGINS;

    return width - 2 * singleMargin;
  },
);

export const getMaxVideosCountInRow = createSelector(
  getWorkingAreaWidth,
  getThumbnailSpacing,
  getVideosInRowCount,
  (width, spacing, inRowCount) => {
    const range = _.range(1, inRowCount + 1);
    const fullyVisibleThumbnailsCount =
      _.find(
        range,
        (itemsPerRow) =>
          itemsPerRow * WIDTH_CONSTRAINTS[0] + (itemsPerRow - 1) * spacing >
          width,
      ) - 1;

    return _.clamp(inRowCount, 1, fullyVisibleThumbnailsCount || inRowCount);
  },
);

const roundToTwoDigits = (value) => Math.round(value * 100) / 100;

export const getThumbnailWidth = createSelector(
  getWorkingAreaWidth,
  getThumbnailSpacing,
  getVideosInRowCount,
  getMaxVideosCountInRow,
  (width, spacing, inRowCount, maxInRowCount) => {
    const itemsInRowCount = Math.min(maxInRowCount, inRowCount);
    const spacingTotal = (itemsInRowCount - 1) * spacing;
    const availableWidth =
      maxInRowCount >= inRowCount || spacing ? width - spacingTotal : width;
    const widthCandidate = roundToTwoDigits(availableWidth / itemsInRowCount);
    return _.clamp(widthCandidate, ...WIDTH_CONSTRAINTS);
  },
);

const getForcedSpacing = createSelector(getThumbnailWidth, (width) => {
  /* eslint-disable curly */
  if (width > 699) return 80;
  if (width > 499) return 60;
  return 50;
});

export const getRowSpacing = createSelector(
  canShowVideoListItemContentBelow,
  getThumbnailSpacing,
  getForcedSpacing,
  (shouldForceMinSpacing, spacing, forcedSpacing) =>
    shouldForceMinSpacing
      ? _.clamp(spacing, forcedSpacing, MAX_SPACING)
      : spacing,
);
