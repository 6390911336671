import { WIX_PRO_GALLERY } from '@wix/app-definition-ids';
import { SearchDocumentType } from '@wix/client-search-sdk';
import { Spec } from '../../lib/specs';
import { IndexableApp, LayoutType } from '../types';

export const ProGalleryApp: IndexableApp = {
  documentType: SearchDocumentType.ProGallery,
  appDefId: WIX_PRO_GALLERY,
  spec: Spec.ProGallery,
  translations: {
    tabLabel: 'searchResults.tabs.label.proGallery',
    tabLabelWithCount: 'searchResults.tabs.label.proGallery.withCount',
    tabAccessibilityLabel:
      'searchResults.tabs.label.proGallery.accessibilityLabel',
  },
  layout: LayoutType.Grid,
};
