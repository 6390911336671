import { combineReducers, Reducer } from 'redux';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';

import videos from './reducers/videos';
import channels from './reducers/channels';
import channelVideos from './reducers/channel-videos';
import configuration from './reducers/configuration';
import appSettings from './reducers/app-settings';
import currentChannelId from './reducers/current-channel-id';
import search from './reducers/search';
import channelInfo from './reducers/channel-info';
import firstChannelVideoId from './reducers/first-channel-video-id';
import shareOverlay from './reducers/share-overlay';
import videoUrlsCache from './reducers/video-urls-cache';
import selectedVideoId from './reducers/selected-video-id';
import player from './reducers/player';
import isVideosTouched from './reducers/is-videos-touched';
import videoCards from './reducers/video-cards';
import payment from './reducers/payment';
import lazyChannelVideos from './lazy-channel-videos/reducer';
import windowSize from './reducers/window-size';
import isLightbox from './reducers/is-lightbox';
import fullScreenModal from './reducers/full-screen-modal';
import comments from './reducers/comments';
import { reducer as hydratedData } from './hydrated-data/hydrated-data';
import playback from './reducers/playback';
import controllerState from './reducers/controller-state';
import { RootState } from './root-state';

export const createRootReducer: CreateRootReducer = ({ history }) =>
  combineReducers({
    router: connectRouter(history),
    search,
    entities: combineReducers({
      videos,
      channels,
    }),
    channelVideos,
    currentChannelId,
    channelInfo,
    firstChannelVideoId,
    lazyChannelVideos,
    windowSize,
    isLightbox,
    videoUrlsCache,
    selectedVideoId,
    player,
    isVideosTouched,
    payment,
    configuration,
    appSettings,
    videoCards,
    shareOverlay,
    fullScreenModal,
    comments,
    hydratedData,
    playback,
    controllerState,
  });

export default createRootReducer;

export type CreateRootReducer<S extends RootState = RootState> = (options: {
  history: History;
}) => Reducer<S>;
