import _ from 'lodash';
import { Reducer } from 'redux';
import { handleActions } from 'redux-actions';
import { NAMES as CHANNEL_VIDEOS_LIST } from '../actions/channel/videos/list-public';
import { APP_SETTINGS_UPDATE } from '../actions/app-settings';
import { getChannelId } from '../../utils/app-settings';

export type FirstChannelVideoIdStateAndPayload = string | null | undefined;

const defaultState: FirstChannelVideoIdStateAndPayload = null;
let oldChannelId: FirstChannelVideoIdStateAndPayload = null;

const resetOnChannelIdChange: Reducer<FirstChannelVideoIdStateAndPayload> = (
  state,
  { payload },
) => {
  const channelId = getChannelId(payload);
  if (channelId && oldChannelId && oldChannelId !== channelId) {
    oldChannelId = channelId;
    return defaultState;
  }
  return state;
};

const saveVideoId: Reducer<FirstChannelVideoIdStateAndPayload> = (
  state: FirstChannelVideoIdStateAndPayload,
  { payload },
) => {
  if (state) {
    return state;
  }
  return _.get(payload, 'response.data.result[0]', null);
};

export default handleActions<FirstChannelVideoIdStateAndPayload>(
  {
    [CHANNEL_VIDEOS_LIST.SUCCESS]: saveVideoId,
    [APP_SETTINGS_UPDATE]: resetOnChannelIdChange,
  },
  defaultState,
);
