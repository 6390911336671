import _ from 'lodash';
import {
  AsyncActionPayload,
  createAsyncActions,
} from '../../helpers/create-async-actions';
import {
  getMemberInfo,
  MemberInfoData,
  MemberInfoItem,
} from '../../../api/public/channel/channel';
import { getHydratedData } from '../../hydrated-data/hydrated-data';
import { getCurrentSiteUser } from '../../../selectors/current-site-user';
import { isPricingPlanEnabled } from '@wix/wix-vod-shared/common';
import { getBaseUrl } from '../../../selectors/api';
import { ActionCreator } from '../../redux.types';
import { Action } from 'redux-actions';

type GetPaymentInfoParams = {
  id: string;
  options: {
    instance: string;
    items: string[];
  };
};
type GetPaymentInfoResponse = {
  videos?: MemberInfoItem[];
  channel?: MemberInfoItem;
};

export type GetPaymentInfoAction = Action<
  AsyncActionPayload<GetPaymentInfoResponse, GetPaymentInfoParams>
>;

export const CHANNEL_PAYMENT_ACTIONS = createAsyncActions<
  GetPaymentInfoResponse | undefined,
  GetPaymentInfoParams | undefined
>('SERVER.CHANNEL.PAYMENT');

export const getPaymentInfo: ActionCreator = function (id) {
  return async function (dispatch, getState, { handlers }) {
    const state = getState();
    const baseUrl = getBaseUrl(state);
    const isPricingPlan = isPricingPlanEnabled({
      dealInfo:
        // TODO: fix this ridiculous elvis chain
        state.hydratedData.data?.channel?.data?.deal_info ||
        state.channelInfo.info.dealInfo,
    });

    const currentSiteUser = getCurrentSiteUser(state);
    const { instance, memberInfo: hydratedMemberInfo } = getHydratedData(state);

    if (!(currentSiteUser && currentSiteUser.id)) {
      return Promise.resolve().then(() =>
        dispatch(CHANNEL_PAYMENT_ACTIONS.SUCCESS(undefined, undefined)),
      );
    }

    if (isPricingPlan) {
      const channelId = state.currentChannelId.currentChannelId;

      handlers.requestPricingPlanPaymentInfo(
        channelId as string,
        currentSiteUser.id,
      );
    }

    const {
      channelVideos: { ids: items },
    } = state;
    const options = {
      instance,
      items,
    };

    const params: GetPaymentInfoParams = { id, options };
    dispatch(CHANNEL_PAYMENT_ACTIONS.START(params));

    return getMemberInfo(baseUrl, id, instance, hydratedMemberInfo).then(
      function (response: MemberInfoData) {
        const dataItems = _.get(response, 'data.items', []);

        if (!dataItems.length) {
          dispatch(CHANNEL_PAYMENT_ACTIONS.SUCCESS(undefined, undefined));
          return response;
        }

        const [channelData, videoDataList] = _.partition(
          dataItems,
          (item) => !item.itemId,
        );

        const channel = _.get(channelData, '[0]');

        const videos = videoDataList.map(({ dgsInfo, itemId, listId }) => ({
          dgsInfo,
          itemId,
          listId,
        }));

        const resp: GetPaymentInfoResponse = {
          videos,
          channel: _.pick(channel, 'dgsInfo', 'listId'),
        };

        dispatch(CHANNEL_PAYMENT_ACTIONS.SUCCESS(params, resp));
        return resp;
      },

      function (reason) {
        const meta = {
          analytics: { type: 'error', name: 'shared.channel.payment.get' },
        };

        if (_.isError(reason)) {
          dispatch(CHANNEL_PAYMENT_ACTIONS.FAIL(reason, null, meta));
        } else {
          dispatch(CHANNEL_PAYMENT_ACTIONS.FAIL(params, reason, meta));
        }

        return Promise.reject(reason);
      },
    );
  };
};
