import { handleActions } from 'redux-actions';
import {
  UPDATE_WINDOW_SIZE,
  UpdateWindowSizeAction,
} from '../actions/window-size';

const defaultState = {
  width: 0,
  height: 0,
};

export type WindowSizeState = {
  width: number;
  height: number;
};

export default handleActions<WindowSizeState, any>(
  {
    [UPDATE_WINDOW_SIZE]: (state, { payload }: UpdateWindowSizeAction) => ({
      ...state,
      ...payload,
    }),
  },
  defaultState,
);
