import { IHttpClient } from '@wix/http-client';
import {
  createPublicPurchaseService,
  PublicVideoApiService,
  PublicChannelVideosService,
  PublicWidgetsService,
  YoutubeVideosService,
  PublicPurchaseService,
} from '@wix/wix-vod-api/public';
import {
  PublicVideoCards,
  PublicChannel,
  YoutubeService,
  PublicChannelVideos,
} from '@wix/wix-vod-api-site-based/public';

interface PublicAPI {
  purchase: PublicPurchaseService;
  video: PublicVideoApiService;
  channelVideos: PublicChannelVideosService;
  widgets: PublicWidgetsService;
  youtubeVideosService: YoutubeVideosService;
  publicWidgetsService: PublicWidgetsService;
  // site-based
  oldPublicVideoCardsService: PublicVideoCards;
  oldPublicChannelService: PublicChannel;
  oldYoutubeService: YoutubeService;
  oldPublicChannelVideos: PublicChannelVideos;
}

let publicApi: PublicAPI | undefined;

export function getPublicApi(): PublicAPI {
  if (publicApi === undefined) {
    throw Error(
      'public Api has not yet been initialized, call `initPublicServices` first',
    );
  }
  return publicApi;
}

const initPublicServicesV2 = (httpClient: IHttpClient) => {
  publicApi = {
    purchase: createPublicPurchaseService(httpClient),
    video: new PublicVideoApiService(httpClient),
    channelVideos: new PublicChannelVideosService(httpClient),
    widgets: new PublicWidgetsService(httpClient),
    youtubeVideosService: new YoutubeVideosService(httpClient),
    publicWidgetsService: new PublicWidgetsService(httpClient),
    oldPublicVideoCardsService: new PublicVideoCards(httpClient),
    oldPublicChannelService: new PublicChannel(httpClient),
    oldYoutubeService: new YoutubeService(httpClient),
    oldPublicChannelVideos: new PublicChannelVideos(httpClient),
  };
};

const initPublicServicesV3ToV2Mapping = (
  httpClient: IHttpClient,
  instance: string,
  baseUrl: string,
  v3MappingBaseUrl: string,
) => {
  publicApi = {
    purchase: createPublicPurchaseService(httpClient),
    video: new PublicVideoApiService(httpClient, v3MappingBaseUrl),
    channelVideos: new PublicChannelVideosService(httpClient, v3MappingBaseUrl),
    widgets: new PublicWidgetsService(httpClient),
    youtubeVideosService: new YoutubeVideosService(httpClient),
    publicWidgetsService: new PublicWidgetsService(httpClient),
    oldPublicVideoCardsService: new PublicVideoCards(
      httpClient,
      v3MappingBaseUrl,
    ),
    oldPublicChannelService: new PublicChannel(httpClient, v3MappingBaseUrl),
    oldYoutubeService: new YoutubeService(httpClient),
    oldPublicChannelVideos: new PublicChannelVideos(
      httpClient,
      v3MappingBaseUrl,
    ),
  };
};

type V3ToV2MappingOptions =
  | { isMappingOn: true; v3ToV2MappingBaseUrl: string }
  | { isMappingOn: false };

export function initPublicServices(
  httpClient: IHttpClient,
  instance: string,
  baseUrl: string,
  v3ToV2MappingOptions: V3ToV2MappingOptions = { isMappingOn: false },
) {
  if (v3ToV2MappingOptions.isMappingOn) {
    initPublicServicesV3ToV2Mapping(
      httpClient,
      instance,
      baseUrl,
      v3ToV2MappingOptions.v3ToV2MappingBaseUrl,
    );
  } else {
    initPublicServicesV2(httpClient);
  }
}
