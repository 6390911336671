import * as React from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import { SearchDocumentType } from '@wix/client-search-sdk';
import { ImageResizeMode } from '@wix/search-settings-client';

import { Thumbnail } from '../Thumbnail';
import { Title } from '../Title';
import { LayoutItemProps } from '../Layout.types';

import { st, classes } from './GridLayoutItem.st.css';
import { useAppSettings } from '../../hooks';

const THUMBNAIL_WIDTH = {
  DESKTOP: 220,
  MOBILE: 130,
};

const getThumbnailWidth = (isMobile: boolean) =>
  isMobile ? THUMBNAIL_WIDTH.MOBILE : THUMBNAIL_WIDTH.DESKTOP;

const THUMBNAIL_HEIGHT = {
  DESKTOP: 220,
  MOBILE: 105,
};

const getThumbnailHeight = (isMobile: boolean) =>
  isMobile ? THUMBNAIL_HEIGHT.MOBILE : THUMBNAIL_HEIGHT.DESKTOP;

type IGridLayoutItemProps = LayoutItemProps;

export const GridLayoutItem: React.FC<IGridLayoutItemProps> = ({
  item,
  onLinkClick,
  renderItemExtention,
}) => {
  const { isMobile, isEditorX } = useEnvironment();
  const appSettings = useAppSettings();

  const { image, title, url, focalPointX, focalPointY } = item;

  const thumbnailWidth = getThumbnailWidth(isMobile);
  const thumbnailHeight = getThumbnailHeight(isMobile);
  const documentType = item.documentType;

  const imageResizeMode =
    documentType === SearchDocumentType.Products
      ? appSettings.productImageResizeMode
      : ImageResizeMode.Crop;

  return (
    <li
      data-hook="grid-layout-item"
      className={st(classes.root, {
        mobileView: isMobile,
        fluid: isEditorX,
      })}
    >
      <Thumbnail
        height={thumbnailHeight}
        image={image!}
        maxResponsiveImageHeight={578}
        maxResponsiveImageWidth={578}
        onClick={(e) => onLinkClick?.(e, 'image')}
        resizeMode={imageResizeMode}
        title={title}
        url={url}
        width={thumbnailWidth}
        focalPoint={
          focalPointX !== undefined && focalPointY !== undefined
            ? { x: focalPointX, y: focalPointY }
            : undefined
        }
      />
      {!!title && (
        <div className={classes.titleWrapper} dir="auto">
          <Title
            onClick={(e) => onLinkClick?.(e, 'title')}
            title={title}
            url={url}
          />
        </div>
      )}
      {renderItemExtention?.(item)}
    </li>
  );
};
