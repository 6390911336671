import _ from 'lodash';
import { createSelector } from 'reselect';
import { getHydratedData } from '../redux/hydrated-data/hydrated-data';
import * as viewModeSelectors from './view-mode';

export function getCurrentSiteUser(state) {
  const isSiteMode = viewModeSelectors.isSiteMode(state);
  const { currentSiteUser } = getHydratedData(state);

  if (!isSiteMode) {
    return null;
  }

  return currentSiteUser;
}

export const getCurrentUserEmail = createSelector(
  getCurrentSiteUser,
  (currentSiteUser) => _.get(currentSiteUser, 'email', ''),
);
