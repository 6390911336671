import _ from 'lodash';
import { handleActions } from 'redux-actions';
import {
  GetPublicChannelAction,
  NAMES as GET_PUBLIC_CHANNEL,
} from '../actions/channel/get-public';
import {
  CHANNEL_PAYMENT_ACTIONS,
  GetPaymentInfoAction,
} from '../actions/channel/payment';
import { Channel } from '../types';

export type ChannelsState = Record<string, Channel>;

export default handleActions<ChannelsState, any>(
  {
    [GET_PUBLIC_CHANNEL.SUCCESS]: (
      state: ChannelsState,
      { payload }: GetPublicChannelAction,
    ) => {
      const channels = payload.response?.data.entities.channels;

      if (channels) {
        return _.merge({}, state, channels);
      }

      return state;
    },
    [CHANNEL_PAYMENT_ACTIONS.NAMES.SUCCESS]: (
      state: ChannelsState,
      { payload }: GetPaymentInfoAction,
    ) => {
      const channel = payload.response?.channel;
      if (channel) {
        const { dgsInfo } = channel;
        const newState = {
          ...state,
        };

        newState[channel.listId] = {
          ...newState[channel.listId],
          dgsInfo,
        };

        return newState;
      }
      return state;
    },
  },
  {},
);
