import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';
import applicationJson from '../../../.application.json';

export type ISettingsParams = {
  greetingsText: SettingsParamType.Text;
  channelId: SettingsParamType.String;
  showTitleForMainItem: SettingsParamType.Boolean;
};

export const settingsParams = createSettingsParams<ISettingsParams>({
  greetingsText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      `${t('app.settings.defaults.greetingsPretext')} ${
        applicationJson.appName
      }`,
  },
  channelId: {
    type: SettingsParamType.String,
    getDefaultValue: () => 'a1ca9dac7ee44d52a41868329471105b',
  },
  showTitleForMainItem: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
});
export default settingsParams;
