import _ from 'lodash';

export enum STATS_EVENTS {
  VIDEO_PLAY_TRAILER = 'VIDEO_PLAY_TRAILER',
  VIDEO_PLAY_COMPLETE = 'VIDEO_PLAY_COMPLETE',
  VIDEO_SHARE = 'VIDEO_SHARE',
  CHANNEL_SHARE = 'CHANNEL_SHARE',
}

const getEvents = ({ ownerId }: any) => ({
  [STATS_EVENTS.VIDEO_PLAY_TRAILER]: {
    messageId() {
      return Date.now();
    },
    metrics: [
      {
        type: 'video',
        reportMetrics: [{ name: 'view', value: 1 }],
        properties: {
          view_type: 'trailer',
          user_id: ownerId,
          video_id({ videoItem }: any) {
            return videoItem.id;
          },
          channel_id({ channel }: any) {
            return channel.id;
          },
        },
      },
    ],
  },
  [STATS_EVENTS.VIDEO_PLAY_COMPLETE]: {
    messageId() {
      return Date.now();
    },
    metrics: [
      {
        type: 'video',
        reportMetrics: [{ name: 'view', value: 1 }],
        properties: {
          view_type: 'video',
          user_id: ownerId,
          video_id({ videoItem }: any) {
            // only youtube feed videos have no id, use externalId instead
            return videoItem.id || videoItem.externalId;
          },
          channel_id({ channel }: any) {
            return channel.id;
          },
        },
      },
    ],
  },
  [STATS_EVENTS.VIDEO_SHARE]: {
    messageId() {
      return Date.now();
    },
    metrics: [
      {
        type: 'video',
        reportMetrics: [{ name: 'share', value: 1 }],
        properties: {
          user_id: ownerId,
          video_id({ videoItem }: any) {
            // only youtube feed videos have no id, use externalId instead
            return videoItem.id || videoItem.externalId;
          },
          channel_id({ channel }: any) {
            return channel.id;
          },
          social({ network }: any) {
            return network.name;
          },
        },
      },
    ],
  },
  [STATS_EVENTS.CHANNEL_SHARE]: {
    messageId() {
      return Date.now();
    },
    metrics: [
      {
        type: 'channel',
        reportMetrics: [{ name: 'share', value: 1 }],
        properties: {
          user_id: ownerId,
          channel_id({ channel }: any) {
            return channel.id;
          },
          social({ network }: any) {
            return network.name;
          },
        },
      },
    ],
  },
});

function fillParams(eventParams: any, data: any): any {
  return _.mapValues(eventParams, (value) => {
    if (_.isFunction(value)) {
      return value(data);
    } else if (_.isArray(value)) {
      return _.map(value, (item) => fillParams(item, data));
    } else if (_.isObject(value)) {
      return fillParams(value, data);
    }

    return value;
  });
}

export function buildReport(
  eventName: STATS_EVENTS,
  data: any,
  ownerId?: string,
) {
  const events = getEvents({ ownerId });
  const event = events[eventName];

  if (!event) {
    return;
  }

  data = _.merge({}, data, { ownerId });

  return fillParams(event, data);
}
