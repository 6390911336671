import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getWidgetSize } from '../../../selectors/layout';
import * as viewModeSelectors from '../../../selectors/view-mode';
import { getWixSDK } from '../../../utils/wix-sdk';
import { resizeComponent } from '../../../worker/actions/resize/resize-component';
import { setWidgetHeight } from '../../../worker/actions/resize/set-widget-height';

const mapStateToProps = (state) => ({
  // lazyVideoLists is needed to trigger height calculations
  lazyVideoLists: state.lazyVideoLists,
  windowSize: state.windowSize,
  widgetSize: getWidgetSize(state),
  isEditor: viewModeSelectors.isEditorMode(state),
});

const mapDispatchToProps = {
  resizeComponent,
  setWidgetHeight,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class // lazyVideoLists is needed to trigger height calculations
  WidgetHeightByContentSync extends React.Component {
    static propTypes = {
      windowSize: PropTypes.object.isRequired,
      widgetSize: PropTypes.object.isRequired,
      children: PropTypes.node.isRequired,
      isEditor: PropTypes.bool.isRequired,
      resizeComponent: PropTypes.func.isRequired,
      setWidgetHeight: PropTypes.func.isRequired,
    };

    async componentDidMount() {
      await this.handleIframeResize();
      window.addEventListener('resize', this.handleIframeResize);
    }

    async componentDidUpdate(prevProps) {
      const {
        widgetSize: { height, width },
      } = this.props;

      if (prevProps.height !== height || prevProps.width !== width) {
        await this.resizeWidget();
      }
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleIframeResize);
    }

    handleIframeResize = _.debounce(async () => {
      await this.resizeWidget();
    }, 300);

    resizeWidget = async () => {
      const WixSDK = getWixSDK();

      if (!WixSDK || !WixSDK.getBoundingRectAndOffsets) {
        return;
      }

      const { rect } = await WixSDK.getBoundingRectAndOffsets();
      const { widgetSize, isEditor, resizeComponent, setWidgetHeight } =
        this.props;
      const { height, width } = widgetSize;
      const isSizeChanged = rect.height !== height || rect.width !== width;

      if (isSizeChanged) {
        if (isEditor) {
          resizeComponent({
            height,
            width,
          });
        } else {
          setWidgetHeight(height, width);
        }
      }
    };

    render() {
      const { children, windowSize } = this.props;
      return (
        <div style={{ width: windowSize.width }} role="presentation">
          {children}
        </div>
      );
    }
  },
);
