import { createAction, Action } from 'redux-actions';

export const UPDATE_WINDOW_SIZE = 'CLIENT.WINDOW_SIZE.UPDATE';

export type UpdateWindowSizePayload = {
  width: number;
  height: number;
};

export type UpdateWindowSizeAction = Action<UpdateWindowSizePayload>;

export const updateWindowSize =
  createAction<UpdateWindowSizePayload>(UPDATE_WINDOW_SIZE);
