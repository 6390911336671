import _ from 'lodash';
import { APP_SETTINGS_VERSION_LATEST } from '@wix/wix-vod-constants/app-settings';
import { isDemoChannel } from './channel-helpers';

const createIdGetter = (key) => (source) => {
  const value = _.get(source, key);
  return value ? value.replace(/'/g, '') : value;
};

export const getChannelId = createIdGetter('fonts.channelId');

export const getVideoId = createIdGetter('fonts.videoId');

export const isLatestVersion = (appSettings) =>
  _.get(appSettings, 'numbers.settingsVersion') === APP_SETTINGS_VERSION_LATEST;

export const isNewInstallation = (appSettings) => {
  const channelId = getChannelId(appSettings);
  return !channelId || isDemoChannel(channelId);
};
