import * as React from 'react';

import { st, classes, vars } from './Description.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { xss } from '../../lib/xss';

interface IDescriptionProps {
  description: string;
  maxLineCount?: number;
}

export const Description: React.FC<IDescriptionProps> = ({
  description,
  maxLineCount = 3,
}) => {
  const { isMobile, isEditorX } = useEnvironment();

  if (!description) {
    return null;
  }

  return (
    <p
      style={{ [vars.maxLineCount]: maxLineCount }}
      className={st(classes.root, {
        mobileView: isMobile,
        fluid: isEditorX,
      })}
      data-hook="item-description"
      dangerouslySetInnerHTML={{ __html: xss(description) }}
    />
  );
};
