import React from 'react';
import { withTranslation } from '@wix/yoshi-flow-editor';
import styles from './slider-empty-state.scss';

export const SliderEmptyState = withTranslation(
  class extends React.Component {
    render() {
      return (
        <div className={styles.sliderEmptyState}>
          {this.props.t('widget.this-channel-is-coming-soon')}
        </div>
      );
    }
  },
);
