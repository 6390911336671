import React, { FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { st, classes } from './coming-soon.st.css';
import {
  Picture,
  PictureMode,
  VideoAspectRatio,
} from '@wix/wix-vod-shared/components';
import {
  MAX_COVER_IMAGE_HEIGHT,
  MAX_COVER_IMAGE_WIDTH,
} from '../../constants/sizes';

type Props = {
  coverUrl: string;
};

export const ComingSoon: FC<Props> = function ({ coverUrl }) {
  const { t } = useTranslation();

  return (
    <div className={st(classes.root)}>
      <VideoAspectRatio>
        {coverUrl && (
          <Picture
            src={coverUrl}
            className={st(classes.picture)}
            mode={PictureMode.FIT_TO_WIDTH}
            breakpoints={[
              {
                width: MAX_COVER_IMAGE_WIDTH,
                height: MAX_COVER_IMAGE_HEIGHT,
                min: 0,
              },
            ]}
          />
        )}
        <div className={st(classes.text)}>
          {t('widget.this-channel-is-coming-soon')}
        </div>
      </VideoAspectRatio>
    </div>
  );
};
