import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { demoChannel } from '../../constants/demo-channel';
import {
  APP_SETTINGS_UPDATE,
  SetAppSettingsPayload,
} from '../actions/app-settings';
import { NAMES as CHANNEL_GET } from '../actions/channel/get-public';
import { getChannelId } from '../../utils/app-settings';

export type CurrentChannelIdState = {
  currentChannelId: string | null;
  prevChannelIdFromSettings?: string | null;
};

export default handleActions<CurrentChannelIdState, SetAppSettingsPayload>(
  {
    [APP_SETTINGS_UPDATE]: (state, { payload }) => {
      if (!payload.fonts) {
        return state;
      }
      let { prevChannelIdFromSettings } = state;
      // For a case when user had channel and then removed it, so it falls back to demoId
      // When any setting in appSettings changes, it retries to fetch deleted channel, that
      // said goes into finite loop of GET/FAIL actions.
      const nextChannelId = getChannelId(payload);

      if (prevChannelIdFromSettings === nextChannelId) {
        return {
          currentChannelId: state.currentChannelId || demoChannel.id,
          prevChannelIdFromSettings,
        };
      }

      prevChannelIdFromSettings = nextChannelId;

      return {
        currentChannelId:
          prevChannelIdFromSettings || state.currentChannelId || demoChannel.id,
        prevChannelIdFromSettings,
      };
    },
    [CHANNEL_GET.FAIL]: (state, { payload }) => {
      if (_.get(payload, 'response.error.code') === -7710) {
        return { ...state, currentChannelId: demoChannel.id };
      }

      return state;
    },
  },
  { currentChannelId: null, prevChannelIdFromSettings: undefined },
);
