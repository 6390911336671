import { isEmpty } from 'lodash';
import { getWixSDK } from '../wix-sdk';
import { getCompId } from '../comp-id';
import { getPublicApi } from '../../api/public';
import { privateApi } from '../../api/private';

export const getWidgetId = () =>
  `${getWixSDK().Utils.getInstanceId()}-${getCompId()}`;

export const getWidgetSettings = () => {
  const publicApi = getPublicApi();
  return publicApi.widgets.getWidgetSettings(getWidgetId());
};

export const updateWidgetSettings = (settings) =>
  privateApi.widgets.updateWidgetSettings(getWidgetId(), settings);

const getUpdateEntities = ({
  channelId,
  videoId,
  savedChannelId,
  savedVideoId,
}) => {
  const updateData = {};

  if (channelId && channelId !== savedChannelId) {
    updateData.listId = channelId;
  }

  if (videoId && videoId !== savedVideoId) {
    updateData.listId = channelId;
    updateData.itemId = videoId;
  }

  return updateData;
};

export function updateWidget(settings) {
  const WixSDK = getWixSDK();

  return WixSDK.getComponentInfo().then(({ pageId }) => {
    const instanceId = WixSDK.Utils.getInstanceId();
    const compId = getCompId();

    return privateApi.widgets.updateWidget(getWidgetId(), {
      instanceId,
      compId,
      pageId,
      settings,
    });
  });
}

export const updateDeeplink = (channelId, videoId) => {
  return getWidgetSettings()
    .then((data) => {
      const savedChannelId = data.listId;
      const savedVideoId = data.itemId;

      const update = getUpdateEntities({
        channelId,
        videoId,
        savedChannelId,
        savedVideoId,
      });

      if (!isEmpty(update)) {
        return updateWidgetSettings(update);
      }
    })
    .catch(({ status }) => {
      // create widget info in case it's not saved yet
      if (status === 404) {
        const update = getUpdateEntities({ channelId, videoId });

        if (!isEmpty(update)) {
          return updateWidget(getUpdateEntities({ channelId, videoId }));
        }
      }
    });
};
