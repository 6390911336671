import { handleActions, createAction } from 'redux-actions';
import { VIDEO_PLAYBACK_STATUSES } from '@wix/wix-vod-constants/common';
import { selectVideo } from '../actions/select-video';
import { ActionCreator } from '../redux.types';

export const NAMES = {
  ABORT_PLAY: 'CLIENT.PLAYBACK.ABORT_PLAY',
  REQUEST_PLAY: 'CLIENT.PLAYBACK.REQUEST_PLAY',
  REQUEST_PAUSE: 'CLIENT.PLAYBACK.REQUEST_PAUSE',
  SET_PLAYING: 'CLIENT.PLAYBACK.SET_PLAYING',
  SET_PAUSED: 'CLIENT.PLAYBACK.SET_PAUSED',
  SET_ENDED: 'CLIENT.PLAYBACK.SET_ENDED',
  SET_MUTED: 'CLIENT.PLAYBACK.SET_MUTED',
  SET_INITIAL: 'CLIENT.PLAYBACK.SET_INITIAL',
};

const {
  INITIAL,
  PLAY_ABORTED,
  PLAY_REQUESTED,
  PAUSE_REQUESTED,
  PLAYING,
  PAUSED,
  ENDED,
} = VIDEO_PLAYBACK_STATUSES;

export type PlaybackState = {
  muted: boolean;
  videos: {
    [key: string]: VIDEO_PLAYBACK_STATUSES;
  };
};

const defaultState: PlaybackState = {
  muted: false,
  videos: {},
};

const requestPlayAction = createAction(NAMES.REQUEST_PLAY);
export const abortPlay = createAction(NAMES.ABORT_PLAY);
export const requestPause = createAction(NAMES.REQUEST_PAUSE);
export const setInitial = createAction(NAMES.SET_INITIAL);
export const setPlaying = createAction(NAMES.SET_PLAYING);
export const setPaused = createAction(NAMES.SET_PAUSED);
export const setEnded = createAction(NAMES.SET_ENDED);
export const setMuted = createAction(NAMES.SET_MUTED);

export const requestPlay: ActionCreator =
  (id, muted = false) =>
  (dispatch) => {
    dispatch(selectVideo(id));
    dispatch(setMuted(muted));
    dispatch(requestPlayAction(id));
  };

const setStatusHandler =
  (status: VIDEO_PLAYBACK_STATUSES) =>
  (
    state: PlaybackState,
    { payload: id }: { payload: string | boolean },
  ): PlaybackState => ({
    ...state,
    videos: {
      ...state.videos,
      [id as string]: status,
    },
  });

const setMutedHandler = (
  state: PlaybackState,
  { payload: muted }: { payload: string | boolean },
): PlaybackState => ({
  ...state,
  muted: muted as boolean,
});

export default handleActions<PlaybackState, string | boolean>(
  {
    [NAMES.ABORT_PLAY]: setStatusHandler(PLAY_ABORTED),
    [NAMES.REQUEST_PLAY]: setStatusHandler(PLAY_REQUESTED),
    [NAMES.REQUEST_PAUSE]: setStatusHandler(PAUSE_REQUESTED),
    [NAMES.SET_INITIAL]: setStatusHandler(INITIAL),
    [NAMES.SET_PLAYING]: setStatusHandler(PLAYING),
    [NAMES.SET_PAUSED]: setStatusHandler(PAUSED),
    [NAMES.SET_ENDED]: setStatusHandler(ENDED),
    [NAMES.SET_MUTED]: setMutedHandler,
  },
  defaultState,
);
