import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isInFullScreen } from '../selectors/player';
import { updateWindowSize } from '../redux/actions/window-size';

const mapStateToProps = (state) => ({
  isInFullScreen: isInFullScreen(state),
  windowSize: state.windowSize,
});

const mapDispatchToProps = {
  updateWindowSize,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class WindowSize extends React.Component {
    static propTypes = {
      isInFullScreen: PropTypes.bool.isRequired,
      windowSize: PropTypes.object.isRequired,
      updateWindowSize: PropTypes.func.isRequired,
      children: PropTypes.node.isRequired,
    };

    componentDidMount() {
      window.addEventListener('resize', this.updateSize);

      // For cases, when editor don`t show iFrame from not selected page at mobile mode
      this.updateWidgetSizeInterval = setInterval(this.updateSize, 200);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateSize);
      clearInterval(this.updateWidgetSizeInterval);
    }

    updateSize = () => {
      // Need to wait for setting isInFullScreen to store
      // TODO use isFullScreenRequested and remove timeout
      setTimeout(() => {
        this._updateSize();
      });
    };

    _updateSize = _.debounce(() => {
      if (this.props.isInFullScreen) {
        return;
      }

      clearInterval(this.updateWidgetSizeInterval);

      if (!this.containerRef) {
        return;
      }

      const { clientWidth, clientHeight } = this.containerRef;

      const nextWindowSize = {
        width: clientWidth,
        height: clientHeight,
      };

      if (nextWindowSize.width === 0 || nextWindowSize.height === 0) {
        return;
      }

      if (!_.isEqual(nextWindowSize, this.props.windowSize)) {
        this.props.updateWindowSize(nextWindowSize);
      }
    }, 100);

    render() {
      return (
        <div
          data-hook="window-size"
          style={{
            width: '100%',
            height: '100%',
          }}
          ref={(containerRef) => (this.containerRef = containerRef)}
        >
          {this.props.children}
        </div>
      );
    }
  },
);
