import React, { ComponentType, useMemo } from 'react';
import {
  useFedopsLogger,
  withFedopsLogger,
  InjectedFedopsLoggerProps,
} from '@wix/yoshi-flow-editor';

export class Interactions {
  private readonly interactions: Record<string, number> = {};

  constructor(private readonly logger: InjectedFedopsLoggerProps['fedops']) {}

  start(interactionName: string) {
    let count = this.interactions[interactionName] || 0;
    count++;
    this.logger.interactionStarted(interactionName);
    this.interactions[interactionName] = count;
  }

  end(interactionName: string) {
    let count = this.interactions[interactionName];

    if (count > 0) {
      count--;
      this.logger.interactionEnded(interactionName);
      this.interactions[interactionName] = count;
    }
  }
}

export type WithInteractionProps<P> = InjectedFedopsLoggerProps & {
  interactions: Interactions;
} & P;

export function withFedopsInteractions<P>(
  Component: ComponentType<WithInteractionProps<P>>,
) {
  return withFedopsLogger<P & InjectedFedopsLoggerProps>((props) => {
    const { fedops } = props;
    const interactions = useMemo(() => new Interactions(fedops), [fedops]);

    return <Component interactions={interactions} {...props} />;
  });
}

export function useFedopsInteractions() {
  const fedops = useFedopsLogger();

  return useMemo(() => new Interactions(fedops), [fedops]);
}
