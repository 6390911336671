import * as React from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Title } from '../Title';
import { Description } from '../Description';
import { ListLayoutItemProps } from '../Layout.types';
import { ListItemThumbnail } from '../ListItemThumbnail';
import { hasImage } from '../Thumbnail';

import { st, classes } from './ListLayoutItem.st.css';

const defaultRenderContent = (props: ListLayoutItemProps) => {
  const { renderDescription, renderTitle, renderItemExtention, item } = props;
  return (
    <div className={classes.content}>
      {renderTitle ? renderTitle(props) : null}
      {renderDescription ? renderDescription(props) : null}
      {renderItemExtention?.(item)}
    </div>
  );
};

const defaultRenderTitle = ({ item, onLinkClick }: ListLayoutItemProps) => {
  return (
    <Title
      onClick={(e) => onLinkClick?.(e, 'title')}
      title={item.title}
      url={item.url}
    />
  );
};
const defaultRenderDescription = ({ item }: ListLayoutItemProps) => {
  return <Description description={item.description} />;
};

const defaultProps: Partial<ListLayoutItemProps> = {
  renderContent: defaultRenderContent,
  renderTitle: defaultRenderTitle,
  renderDescription: defaultRenderDescription,
};

export const ListLayoutItem = (props: ListLayoutItemProps) => {
  const {
    thumbnailHeight,
    thumbnailWidth,
    item,
    onLinkClick,
    renderContent = defaultRenderContent,
  } = props;
  const { image, title, url } = item;

  const { isMobile, isEditorX } = useEnvironment();

  return (
    <li
      data-hook="list-layout-item"
      className={st(classes.root, {
        fixed: !isEditorX,
        fluid: isEditorX,
        mobileView: isMobile,
      })}
    >
      {hasImage(image) && (
        <ListItemThumbnail
          height={thumbnailHeight}
          image={image}
          onLinkClick={onLinkClick}
          title={title}
          url={url}
          width={thumbnailWidth}
        />
      )}
      {renderContent && renderContent({ ...defaultProps, ...props })}
    </li>
  );
};
