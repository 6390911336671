import _ from 'lodash';

export const SLIDER_PADDING = 33;
export const FORCED_MARGIN_WIDTH = 24;
export const MAX_MARGIN_WIDTH = 100;

export const FAKE_ITEM = {
  publisher: 'Publisher',
  title: _.repeat('a ', 250),
  description: _.repeat('a ', 250),
};
