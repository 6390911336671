import _ from 'lodash';
import { createSelector } from 'reselect';
import { getVideoIdFromAppSettings, isSingleVideo } from './app-settings';
import { getVideoIdFromUrl } from './query-params';
import { getVideosGroupedByIds } from './videos';

export const getVideoIdToSelect = createSelector(
  [isSingleVideo, getVideoIdFromAppSettings, getVideoIdFromUrl],
  (isSingleVideo, singleVideoId, videoIdFromUrl) =>
    isSingleVideo ? singleVideoId : videoIdFromUrl,
);

export const videoToSelectExists = createSelector(
  [getVideoIdToSelect, getVideosGroupedByIds],
  (videoIdToSelect, videos) => {
    return !_.isEmpty(videos[videoIdToSelect]);
  },
);
